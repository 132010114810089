import React from "react";
import { Widget } from "@uploadcare/react-widget";
import mergeImages from "merge-images";

const uploadcarePubKey = "3df9e20ea03750f9c279";

export default function HelloWorld() {
  const [download, setDownload] = React.useState({
    ready: false,
    filename: null,
    href: null,
  });

  const [processing, setProcessing] = React.useState(false);

  const placeholder = "/images/placeholder-400x400.jpeg";
  const flag = "/images/palestine-flag-400x400.png";

  const resetImage = React.useCallback(() => {
    mergeFlag({
      name: "placeholder",
      cdnUrl: placeholder,
      isPlaceholder: true,
    });
    setDownload({ ready: false, filename: null, href: null });
  }, []);

  React.useEffect(() => {
    resetImage();
  }, [resetImage]);

  function validateSize(fileInfo) {
    const maxSize = 8 * 1024 * 1024; // 8mb
    if (fileInfo.size !== null && fileInfo.size > maxSize) {
      alert(
        "Your picture size is too large (more than 8MB). Please select a picture with smaller size and try again ✌️"
      );
      throw new Error("fileMaximumSize");
    }
  }

  function mergeFlag({ name, cdnUrl, isPlaceholder = false }) {
    const options = {
      crossOrigin: "Anonymous",
    };

    // Uncomment to debug
    // console.log("cdnUrl:", cdnUrl);

    const avatar = isPlaceholder ? cdnUrl : `${cdnUrl}-/smart_resize/400x400/`;


    if (!isPlaceholder) setProcessing(true);

    setDownload(state => ({...state, ready: false}));

    mergeImages([avatar, flag], options).then((b64) => {
      const result = document.querySelector("img#result");
      result.src = b64;

      if (!isPlaceholder) {
        const nameSplit = name.split(".");
        const filename = nameSplit.slice(0, -1).join("");
        const ext = nameSplit.pop();
        const suffix = "palestine";

        const downloadFilename = `${filename}-${suffix}.${ext}`;
        result.setAttribute("filename", downloadFilename);

        // Track goal "Upload Image"
        window.fathom.trackGoal("MOWVB9FF", 0);

        setDownload({
          ready: true,
          href: result.src,
          filename: downloadFilename,
        });
      }
      setProcessing(false);
    });
  }

  return (
    <div className="bg-gray-200 h-screen flex flex-col justify-center items-center">
      <div className="max-w-sm px-5">
        <h1 className="text-center mb-2">jatikhwan.com</h1>

        <h1 className="text-lg font-semibold mb-4 text-center">
          Add Palestine flag{" "}
          <span role="img" aria-label="Palestine flag">
            🇵🇸
          </span>{" "}
          to your picture
        </h1>

        <ol className="pb-5">
          <li>1. Click "Choose an image" and select image to upload</li>
          <li>
            2. Once ready, you'll see a "Download" button that you can click
          </li>
          <li>3. Use your new picture in Twitter, Whatsapp, Telegram, etc.</li>
        </ol>

        <div className="text-center">
          <img
            id="result"
            alt="Profile"
            src={flag}
            width="400"
            height="400"
            className="rounded-full w-40 h-40 mx-auto"
          />
          <br />

          <Widget
            publicKey={uploadcarePubKey}
            onChange={mergeFlag}
            crop="1:1"
            imageShrink="800x800"
            validators={[validateSize]}
            clearable
            doNotStore
            imagesOnly
          />
        </div>

        {processing && <span>Adding image..</span>}

        {download.ready && (
          <a
            className="mt-8 px-4 py-1 bg-blue-900 rounded-md text-white"
            href={download.href}
            download={download.filename}
            onClick={() => {
              window.fathom.trackGoal("YEN52VAS", 0);
            }}
          >
            Download
          </a>
        )}

        <div className="mt-8">
          <p>
            Also check my twitter for{" "}
            <a
              className="text-blue-700 underline font-semibold"
              href="https://jatikhwan.com/latest"
              target="_blank"
              rel="noopener noreferrer"
            >
              latest news about Palestine ↗
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
