import React from "react";
import { Helmet } from "react-helmet";

export default function ClientRedirect({ url }) {
  const domain = new URL(url).hostname;

  return (
    <>
      <Helmet>
        <title>Redirecting {domain}…</title>
        <meta http-equiv="refresh" content={`0; URL=${url}`} />
        <link rel="canonical" href={`${url}`} />
      </Helmet>
      <div
        style={{
          color: "#383838",
          fontFamily: "'Menlo', 'Monaco', Courier, monospace",
          fontSize: "0.8em",
          display: "flex",
          height: "90vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Redirecting to {domain}…
      </div>
    </>
  );
}
