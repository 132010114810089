import React from "react";
import AvatarFlag from "./components/AvatarFlag";
import ClientRedirect from "./components/ClientRedirect";

const latestTweets =
  "https://twitter.com/search?q=(from%3Ajatikhwan)%20since%3A2021-05-10&src=typed_query&f=live";

// Map components to routes
const routes = [
  { path: "/", Component: AvatarFlag },
  { path: "/latest", Component: () => <ClientRedirect url={latestTweets} /> },
];

export default routes;
